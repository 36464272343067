import { mean, median } from "mathjs"
//import { variance } from "mathjs"

function processSeriesBar(rawData, startHour, endHour) {
    var tmp = [];
    for (var i = 0; i < rawData.length; i++) {
        var hourNo = Number(rawData[i].time.slice(0, 2));
        if (hourNo >= startHour && hourNo < endHour) {
            tmp.push([
                [rawData[i].date, " ", rawData[i].time].join(""),
                rawData[i].respTime,
            ]);
        }
    }
    return tmp
}

function processSeriesScatter(rawData, startHour, endHour) {
    var tmp = [];
    for (var i = 0; i < rawData.length; i++) {
        var hourNo = Number(rawData[i].time.slice(0, 2));
        if (hourNo >= startHour && hourNo < endHour) {
            //tmp.push([hourNo, Number(rawData[i].respTime)]);
            tmp.push([Number(rawData[i].respTime), i + 1]);
        }
    }
    return tmp
}


function processSeriesPie(rawData, pieComments, thresholds) {

    var tmpGroup = rawData.map((val) => { return Number(val[1]) })
    var dataGroup = _helperProcessBreakdown(tmpGroup, thresholds)
    var seriesPie = _buildSeriesPie(dataGroup, pieComments)

    return seriesPie;
}


function processSeriesBreakdown(rawData, breakdowns, thresholds, pieComments) {
    //group data by breakdown (hour range)
    var tmpGroups = breakdowns.map(() => { return [] }) //creat empty arrays mapping breakdowns array
    for (let i = 0; i < rawData.length; i++) {
        let hourNo = Number(rawData[i].time.slice(0, 2));
        let respTime = Number(rawData[i].respTime)
        for (let j = 0; j < breakdowns.length; j++) {
            let startHour = breakdowns[j][0]
            let endHour = breakdowns[j][1]
            if (hourNo >= startHour && hourNo < endHour) {
                tmpGroups[j].push(respTime)
                break
            }
        }
    }
    //console.log('tmpGroups is ', tmpGroups)

    //to each breakdown's group, group again by respTime thresholds
    var dataGroups = breakdowns.map(() => { return [] })
    for (let k = 0; k < tmpGroups.length; k++) {
        dataGroups[k] = _helperProcessBreakdown(tmpGroups[k], thresholds)
    }

    //console.log('dataGroups is ', dataGroups)
    //console.log('pieComments is', pieComments)

    //build series for echart Pie
    var seriesPieGroups = []
    dataGroups.forEach((item) => {
        seriesPieGroups.push(_buildSeriesPie(item, pieComments))
    })
    //console.log(seriesPieGroups)

    //build series for echart Bar(Histogram)
    var seriesBarGroups = []
    dataGroups.forEach((item) => {
        seriesBarGroups.push(_buildSeriesBar(item))
    })
    //console.log(seriesBarGroups)

    return [seriesPieGroups, seriesBarGroups]
}

function _buildSeriesPie(dataGroup, pieComments) {
    var seriesPie = []
    dataGroup.forEach((val, id) => {
        seriesPie.push({ "name": pieComments[id], "value": val.length })
    })
    return seriesPie
}

function _buildSeriesBar(dataGroup) {
    var seriesBar = { 'mean': [], 'median': [] }
    dataGroup.forEach((item, id) => {
        if (id == dataGroup.length - 1) { return null }
        else if (item.length > 0) {
            seriesBar.mean.push(Math.ceil(mean(item)))
            seriesBar.median.push(Math.ceil(median(item)))
        } else {
            seriesBar.mean.push(''),
                seriesBar.median.push('')
        }
    })
    return seriesBar
}

function _helperProcessBreakdown(tmpGroup, thresholds) {
    if (tmpGroup.length == 0) { return [] } else {
        //console.log(tag, ' input is', tmpGroup)
        let dataGroup = thresholds.map(() => { return [] })
        dataGroup.push([])
        for (let i = 0; i < tmpGroup.length; i++) {
            if (thresholds[thresholds.length - 1] <= tmpGroup[i]) {
                dataGroup[dataGroup.length - 1].push(tmpGroup[i])
            } else {
                for (let j = 0; j < thresholds.length; j++) {
                    if (thresholds[j] > tmpGroup[i]) {
                        dataGroup[j].push(tmpGroup[i])
                        break
                    }
                }
            }
        }
        //console.log(tag, 'dataGroup is', dataGroup)
        return dataGroup
    }
}

function processHistogram(rawData) {
    if (rawData.length == 0) { return [] } else {
        var tmp = rawData.map((arr) => { return Number(arr[1]) })

        //var thresholds = [50, 100, 150, 200, 250, 300, 400, 500, 600, 700, 800, 900, 1000]
        //var thresholds = [20, 40, 60, 80, 100, 120, 140, 160, 180, 200, 220, 240, 260, 280, 300, 320, 340, 360, 380, 400, 420, 440, 460, 480, 500, 520, 540, 560, 580, 600, 620, 640, 660, 680, 700, 720, 740, 760, 780, 800, 820, 840, 860, 880, 900, 920, 940, 960, 980, 1000]
        var thresholds = [100, 200, 300, 400, 1000]

        var tmpGroups = _helperProcessBreakdown(tmp, thresholds)


        var tmp2 = tmpGroups.map((arr) => { return arr.length })
        //customize color of bar chart
        var tmp2Last = tmp2.pop()
        var tmp2LastNew = { value: tmp2Last == 0 ? '' : tmp2Last, itemStyle: { color: 'rgb(238,102,102)' } }
        tmp2.push(tmp2LastNew)


        var tmpMean = tmpGroups.map((arr) => {
            if (arr.length == 0) { return 0 } else { return Math.ceil(mean(arr)) }
        })
        tmpMean.pop() //discard value of ">last threshold"
        var tmpMedian = tmpGroups.map((arr) => {
            if (arr.length == 0) { return 0 } else { return Math.ceil(median(arr)) }
        })
        tmpMedian.pop() //discard value of ">last threshold"


        /*console.log(tmpGroups.map((arr) => {
            if (arr.length == 0) { return 0 } else { return Math.ceil(variance(arr)) }
        }))*/

        //console.log(tmpGroups, tmp2, tmpMedian)
        //console.log(tmp2)
        return [tmp2.map(_helperProcessValue0), tmpMean.map(_helperProcessValue0), tmpMedian.map(_helperProcessValue0)]
    }
}


function _helperProcessValue0(value) {
    return value == 0 ? '' : value
}


export default { processSeriesBar, processSeriesPie, processHistogram, processSeriesScatter, processSeriesBreakdown }