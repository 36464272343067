<template>
  <el-container id="app">
    <el-header>TCPING RESULT</el-header>
    <el-main><Home /></el-main>
  </el-container>
</template>

<script>
import Home from "./views/Home.vue";

export default {
  name: "App",
  components: {
    Home,
  },
};
</script>

<style lang="scss">
body {
  background-color: #f6f6f6;
}

#app {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}

.el-header {
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.1em;
  color: #4d148c;
  font-size: 20px;
}

.chart-title {
  margin-top: 10px;
  letter-spacing: 0.04em;
  margin-bottom: 10px;
}

.chart-subtitle {
  color: #999999;
  font-size: 14px;
  letter-spacing: 0.04em;
}
</style>
