<template>
  <div>
    <div v-show="!chartData.seriesName[0].length" class="comment">
      <i class="el-icon-warning-outline" /> Please load data at the first...
    </div>
    <div v-show="chartData.seriesName[0].length" class="charts">
      <div style="text-align: center">
        <div class="chart-title">{{ name }}</div>
        <div class="chart-subtitle">
          range of time: {{ hourRange[0] }}:00 ~ {{ hourRange[1] - 1 }}:59 (
          {{ resultNo }} resuls )
        </div>
      </div>
      <el-row
        type="flex"
        justify="center"
        class="chart"
        style="margin-top: 30px; padding: 20px 0px"
      >
        <div ref="bar" style="width: 800px; height: 500px"></div>
        <div ref="pie" style="width: 400px; height: 500px"></div
      ></el-row>
      <el-row
        type="flex"
        justify="center"
        class="chart"
        style="margin-top: 30px"
      >
        <div ref="histogram" style="width: 1200px; height: 500px"></div>
      </el-row>
      <el-row
        type="flex"
        justify="center"
        class="chart"
        style="margin-top: 30px"
      >
        <div ref="scatter" style="width: 1200px; height: 500px"></div>
      </el-row>
    </div>
  </div>
</template>

<script>
import statics from "../utils/statics";
import * as echarts from "echarts";
import echartsOptionBar from "../utils/eCharts/echartsOptionBar";
import echartsOptionPie from "../utils/eCharts/echartsOptionPie";
import echartsOptionHistogram from "../utils/eCharts/echartsOptionHistogram";
import echartsOptionScatter from "../utils/eCharts/echartsOptionScatter";

export default {
  props: ["chartData", "hourRange", "pieComments", "thresholds"],
  data() {
    return {
      seriesBar: [[], []],
      name: "",
      resultNo: 0,
      optionBar: {},
      chartBar: null,
      optionPie: {},
      chartPie: null,
      optionHistogram: {},
      chartHistogram: null,
      optionScatter: {},
      chartScatter: null,
    };
  },
  computed: {},
  watch: {
    chartData: {
      deep: true,
      handler() {
        this.updateData();
      },
      immediate: false,
    },
    hourRange: {
      deep: true,
      handler() {
        this.updateData();
      },
      immediate: false,
    },
    thresholds: {
      deep: true,
      handler() {
        this.updateDataPie();
      },
      immediate: false,
    },
    optionBar: {
      deep: true,
      handler(val) {
        this.drawChartBar(val);
      },
      immediate: false,
    },
    optionPie: {
      deep: true,
      handler(val) {
        this.drawChartPie(val);
      },
      immediate: false,
    },
    optionHistogram: {
      deep: true,
      handler(val) {
        this.drawChartHistogram(val);
      },
      immediate: false,
    },
    optionScatter: {
      deep: true,
      handler(val) {
        this.drawChartScatter(val);
      },
      immediate: false,
    },
  },
  methods: {
    updateData() {
      this.name = this.chartData.seriesName[0];

      this.seriesBar = [
        statics.processSeriesBar(
          this.chartData.rawData[0],
          this.hourRange[0],
          this.hourRange[1]
        ),
        statics.processSeriesBar(
          this.chartData.rawData[1],
          this.hourRange[0],
          this.hourRange[1]
        ),
      ];

      this.optionBar.series[0].name = this.chartData.seriesName[0];
      this.optionBar.series[0].data = this.seriesBar[0];
      this.optionBar.series[1].name = this.chartData.seriesName[1];
      this.optionBar.series[1].data = this.seriesBar[1];

      this.updateDataPie();

      //this.optionHistogram.series[0].name = this.chartData.seriesName[0];
      var tmp2 = statics.processHistogram(this.seriesBar[0]);
      this.optionHistogram.series[0].data = tmp2[0];
      this.optionHistogram.series[1].data = tmp2[1];
      this.optionHistogram.series[2].data = tmp2[2];

      this.optionScatter.series[0].data = statics.processSeriesScatter(
        this.chartData.rawData[0],
        this.hourRange[0],
        this.hourRange[1]
      );
    },

    updateDataPie() {
      this.optionPie.series[0].data = statics.processSeriesPie(
        this.seriesBar[0],
        this.pieComments,
        this.thresholds
      );

      this.optionPie.series[1].data = statics.processSeriesPie(
        this.seriesBar[1],
        this.pieComments,
        this.thresholds
      );
    },

    initChart() {
      this.chartBar = echarts.init(this.$refs.bar);
      this.chartPie = echarts.init(this.$refs.pie);
      this.chartHistogram = echarts.init(this.$refs.histogram);

      this.drawChartBar(this.optionBar);
      this.drawChartPie(this.optionPie);
      this.drawChartHistogram(this.optionHistogram);

      this.chartScatter = echarts.init(this.$refs.scatter);
      this.drawChartScatter(this.optionScatter);
    },
    drawChartBar(option) {
      this.resultNo = option.series[0].data.length;
      this.chartBar.setOption(option);
    },
    drawChartPie(option) {
      this.chartPie.setOption(option);
    },
    drawChartHistogram(option) {
      this.chartHistogram.setOption(option);
    },
    drawChartScatter(option) {
      this.chartScatter.setOption(option);
    },
  },
  mounted() {
    this.optionBar = JSON.parse(JSON.stringify(echartsOptionBar));
    this.optionPie = JSON.parse(JSON.stringify(echartsOptionPie));
    this.optionHistogram = JSON.parse(JSON.stringify(echartsOptionHistogram));
    this.optionScatter = JSON.parse(JSON.stringify(echartsOptionScatter));
    this.initChart();
  },
};
</script>

<style scoped>
.comment {
  margin-top: 20px;
  font-size: 13px;
  color: #979797;
  text-align: center;
  letter-spacing: 0.04em;
}
.chart {
  background-color: white;
  box-shadow: 1px 1px 3px #e3e3e3;
}

.charts {
  margin-top: 40px;
}
</style>