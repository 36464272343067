export const echartsOptionPie = {
    tooltip: { formatter: '"{b}": {c} \n results,   {d}%' },
    series: [
        {
            type: 'pie',
            name: '',
            animation: false,
            showEmptyCircle: false,
            center: ['50%', '25%'],
            radius: ['25', '70'],
            label: {
                position: 'outside',
                // formatter: '{strong|{b}\n{d}%} \n({c} results)',
                formatter: '{strong|{b}}\n{d}%',
                rich: {
                    strong: {
                        // fontWeight: 'bold',
                        color: '#b0b0b0',
                        fontSize: '12px'
                    }
                }
            },
            data: [],

        },
        {
            type: 'pie',
            name: '',
            animation: false,
            showEmptyCircle: false,
            center: ['50%', '70%'],
            radius: ['25', '70'],
            label: {
                position: 'outside',
                // formatter: '{strong|{b}\n{d}%} \n({c} results)',
                formatter: '{strong|{b}}\n{d}%',
                rich: {
                    strong: {
                        // fontWeight: 'bold',
                        color: '#b0b0b0',
                        fontSize: '12px'
                    }
                }
            },
            data: [],

        },
    ]
}
export default echartsOptionPie