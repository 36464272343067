<template>
  <div>
    <el-row type="flex" justify="center">
      <el-form inline size="mini">
        <el-form-item>
          <el-button
            @click="drawerSetting = !drawerSetting"
            type="primary"
            icon="el-icon-s-tools"
          ></el-button>
        </el-form-item>
        <el-form-item>
          <el-select v-model="location">
            <el-option
              v-for="item in locations"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-date-picker
            v-model="testDate"
            type="daterange"
            range-separator="to"
            start-placeholder="start date"
            end-placeholder="end date"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-tooltip effect="light">
            <div slot="content">
              MAGS: www+api<br />
              MAGP: www+api+magp
            </div>
            <el-switch v-model="isMagp" active-text="MAGP" inactive-text="MAGS">
            </el-switch
          ></el-tooltip>
        </el-form-item>
        <el-form-item label="chart">
          <el-radio-group v-model="chartOutput">
            <el-radio-button label="chart1.0">1</el-radio-button>
            <el-radio-button label="chart1.1">1+</el-radio-button>
            <el-radio-button label="chart2.0">2</el-radio-button>
            <el-radio-button label="chart2.1">2+</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button
            @click="refresh()"
            :disabled="testDate[0] === ''"
            type="primary"
            icon="el-icon-refresh-right"
            >LOAD DATA</el-button
          ><span></span>
        </el-form-item>

        <el-form-item>
          <el-button @click="drawerFile = !drawerFile" icon="el-icon-upload2"
            >FROM FILE</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>

    <el-drawer
      title="Upload data from file"
      :visible.sync="drawerFile"
      direction="ttb"
    >
      <el-row>
        <el-form label-width="80px" :inline="true">
          <el-form-item label="chart 1">
            <input
              type="file"
              accept=".csv"
              @change="handleFileUpload($event, 'chart1', 0)"
            />
          </el-form-item>
          <el-form-item label="chart 1+">
            <input
              type="file"
              accept=".csv"
              @change="handleFileUpload($event, 'chart1', 1)"
            />
          </el-form-item>

          <el-form-item label="chart 2">
            <input
              type="file"
              accept=".csv"
              @change="handleFileUpload($event, 'chart2', 0)"
            />
          </el-form-item>
          <el-form-item label="chart 2+">
            <input
              type="file"
              accept=".csv"
              @change="handleFileUpload($event, 'chart2', 1)"
            />
          </el-form-item>
        </el-form>
      </el-row>
    </el-drawer>

    <el-drawer
      title="Chart setting"
      :visible.sync="drawerSetting"
      direction="ttb"
    >
      <el-row style="padding: 0px 30px">
        <div>
          show data
          <span style="color: red"
            >{{ echartsOptionCustom.hourRange[0] }}:00 -
            {{ echartsOptionCustom.hourRange[1] - 1 }}:59</span
          >
        </div>
        <el-form size="mini">
          <el-form-item>
            <el-slider
              v-model="echartsOptionCustom.hourRange"
              range
              :step="optionsCustom.sliderHourRange.step"
              :max="optionsCustom.sliderHourRange.max"
              :marks="optionsCustom.sliderHourRange.marks"
            >
            </el-slider
          ></el-form-item>
          <el-form-item>
            <el-button @click="changeHourRange([0, 24])">0-24</el-button>
            <el-button @click="changeHourRange([9, 18])">9-18</el-button>
            <el-button @click="changeHourRange([0, 9])">0-9</el-button>
            <el-button @click="changeHourRange([18, 24])">18-24</el-button
            ><el-button @click="changeHourRange([9, 12])">9-12</el-button
            ><el-button @click="changeHourRange([13, 18])">13-18</el-button>
            <el-button @click="changeHourRange([9, 10])">9-10</el-button>
            <el-button @click="changeHourRange([10, 11])">10-11</el-button>
            <el-button @click="changeHourRange([11, 12])">11-12</el-button
            ><el-button @click="changeHourRange([13, 14])">13-14</el-button>
            <el-button @click="changeHourRange([14, 15])">14-15</el-button>
            <el-button @click="changeHourRange([15, 16])">15-16</el-button>
            <el-button @click="changeHourRange([16, 17])">16-17</el-button>
            <el-button @click="changeHourRange([17, 18])">17-18</el-button>
          </el-form-item>
        </el-form>
      </el-row>
    </el-drawer>

    <ChartGroup
      :chartData="chartData.chart1"
      :echartsOptionCustom="echartsOptionCustom"
      ><template #title>chart 1</template>
    </ChartGroup>

    <ChartGroup
      :chartData="chartData.chart2"
      :echartsOptionCustom="echartsOptionCustom"
      ><template #title>chart 2</template>
    </ChartGroup>

    <!-- <el-collapse v-model="selfOption.collapseActive">
      <el-collapse-item name="2">
        <template slot="title">
          <div class="chart-header">
            <i class="el-icon-document" /> from data file
          </div>
        </template>
      </el-collapse-item>
      <el-collapse-item name="3">
        <template slot="title">
          <div class="chart-header">
            <i class="el-icon-s-tools" /> range of time
          </div>
        </template>

        <el-row :gutter="80" style="padding-left: 50px; padding-right: 50px">
          <el-col> </el-col>
          <el-col :span="12">
              <el-descriptions
                    title="pie breakdown by response time (short to long)"
                    :column="1"
                  > 
              <el-form label-position="right" label-width="200px">
                <div style="text-align: center; padding-bottom: 10px">
                  pie breakdown by response time (short to long)
                </div>
                <el-form-item
                  :label="`${echartsOptionCustom.pieThresholdsComments[0]}: 0 ms ~`"
                >
                  <el-input-number
                  
                    :step="100"
                    v-model="echartsOptionCustom.pieThresholds[0]"
                    style="width: 120px; margin-left: 5px; margin-right: 5px"
                  ></el-input-number
                  >ms
                </el-form-item>
                <el-form-item
                  :label="`${echartsOptionCustom.pieThresholdsComments[1]}: ${echartsOptionCustom.pieThresholds[0]} ms ~`"
                >
                  <el-input-number
                  
                    :step="100"
                    v-model="echartsOptionCustom.pieThresholds[1]"
                    style="width: 120px; margin-left: 5px; margin-right: 5px"
                  ></el-input-number
                  >ms</el-form-item
                >
                <el-form-item
                  :label="`${echartsOptionCustom.pieThresholdsComments[2]}: ${echartsOptionCustom.pieThresholds[1]} ms ~`"
                >
                  <el-input-number
                  
                    :step="100"
                    v-model="echartsOptionCustom.pieThresholds[2]"
                    style="width: 120px; margin-left: 5px; margin-right: 5px"
                  ></el-input-number
                  >ms</el-form-item
                >
                <el-form-item
                  :label="`${echartsOptionCustom.pieThresholdsComments[3]}: ${echartsOptionCustom.pieThresholds[2]} ms ~`"
                >
                </el-form-item>
              </el-form>
            </el-col>
        </el-row>
      </el-collapse-item></el-collapse
    > -->
  </div>
</template>

<script>
// @ is an alias to /src
import Papa from "papaparse";
import ChartGroup from "../components/ChartGroup.vue";
import { loadRecords } from "@/utils/db";

export default {
  components: {
    ChartGroup,
  },
  data() {
    return {
      file: "",
      chartData: {
        chart1: {
          hasData: false,
          rawData: [[], []],
          resultNo: [0, 0],
          seriesName: ["", ""],
        },
        chart2: {
          hasData: false,
          rawData: [[], []],
          resultNo: [0, 0],
          seriesName: ["", ""],
        },
      },

      optionsCustom: {
        sliderHourRange: {
          max: 24,
          step: 1,
          marks: {
            0: "0",
            4: "4",
            8: "8",
            12: "12",
            16: "16",
            20: "20",
            24: "24",
          },
        },
      },
      echartsOptionCustom: {
        seriesLarge: true,
        xAxisType: "time",
        hourRange: [0, 24],
        pieThresholdsComments: ["fast", "medium", "slow", "stuck"],
        pieThresholds: [100, 200, 1000],
        pieBreakdowns: [
          [9, 10],
          [10, 11],
          [11, 12],
          [13, 14],
          [14, 15],
          [15, 16],
          [16, 17],
          [17, 18],
        ],
      },
      selfOption: {
        collapseActive: ["1"],
      },
      testRecords: [],
      testDate: ["2022-11-01", "2022-11-30"],
      isMagp: true,
      locations: [
        { value: "cn_qingdao", label: "QINGDAO" },
        { value: "cn_shanghai", label: "SHANGHAI" },
        { value: "cn_shenzhen", label: "SHENZHEN" },
        { value: "cn_dongguan", label: "DONGGUAN" },
        { value: "jp_tokyo", label: "TOKYO" },
      ],
      location: "cn_shenzhen",
      chartOutput: "chart1.0",
      drawerFile: false,
      drawerSetting: false,
    };
  },
  computed: {
    chartOutputPath() {
      let tmp = this.chartOutput.split(".");
      return [tmp[0], parseInt(tmp[1])];
    },
  },
  watch: {},
  methods: {
    async refresh() {
      this.status = "loading";
      this.testRecords = await loadRecords(
        this.location,
        this.isMagp,
        this.testDate[0],
        this.testDate[1]
      );
      this.status = "done";
      this.chartData[this.chartOutputPath[0]].rawData[this.chartOutputPath[1]] =
        this.testRecords;
      this._builtSeriesName(
        {
          name: `"${this.location.split("_")[1].toUpperCase()}" ${
            this.isMagp ? "MAGIC PLUS" : "MAGIC SHIP"
          } ( ${this.testDate[0]} to ${this.testDate[1]} ) `,
        },
        this.chartOutputPath[0],
        this.chartOutputPath[1]
      );
      this._verifyHasData(this.chartOutputPath[0]);
    },

    changeHourRange(array) {
      this.echartsOptionCustom.hourRange = array;
    },

    handleFileUpload(event, chartId, seriesId) {
      var file = event.target.files[0];
      if (file) {
        this._csvToJson(file, chartId, seriesId);
      } else {
        this._initSeries(chartId, seriesId);
      }
    },

    _csvToJson(file, chartId, seriesId) {
      return new Promise(() => {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            //console.log(results.data);
            this.chartData[chartId].rawData[seriesId] = results.data;
            this._builtSeriesName(file, chartId, seriesId);
            this._verifyHasData(chartId);
          },
        });
      });
    },

    _verifyHasData(chartId) {
      var tmp = this.chartData[chartId].rawData;
      this.chartData[chartId].resultNo = [tmp[0].length, tmp[1].length];
      this.chartData[chartId].hasData = tmp[0].length > 0 || tmp[1].length > 0;
    },

    _initSeries(chartId, seriesId) {
      var chart = this.chartData[chartId];
      chart.seriesName[seriesId] = "";
      chart.rawData[seriesId] = [];
      this._verifyHasData(chartId);
    },

    _builtSeriesName(file, chartId, seriesId) {
      var chart = this.chartData[chartId];
      // chart.seriesName[seriesId] = file.name.slice(0, -4).replaceAll("_", ".");
      chart.seriesName[seriesId] = file.name;
    },
  },
  mounted() {
    // this.refresh();
  },
};
</script>

<style>
</style>