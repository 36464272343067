<template>
  <div>
    <div ref="pie" style="width: 380px; height: 280px"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import echartsOptionPieBreakdown from "../utils/eCharts/echartsOptionPieBreakdown";
export default {
  props: ["seriesData", "eChartTitle"],
  data() {
    return { option: {}, chart: null };
  },
  watch: {
    seriesData: {
      deep: true,
      handler(val) {
        this.updateData(val);
      },
      immediate: false,
    },
    option: {
      deep: true,
      handler(val) {
        this.drawChart(val);
      },
      immediate: false,
    },
  },
  methods: {
    updateData(val) {
      this.option.title.text = this.eChartTitle;
      this.option.series[0].data = val;
    },
    initChart() {
      this.chart = echarts.init(this.$refs.pie);
      this.updateData(this.seriesData);
    },
    drawChart(option) {
      this.chart.setOption(option);
    },
  },
  mounted() {
    this.option = JSON.parse(JSON.stringify(echartsOptionPieBreakdown));
    this.initChart();
  },
};
</script>

<style>
</style>