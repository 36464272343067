export const echartsOptionPieBreakdown = {
    title: { text: 'pie chart', left: 'center', textStyle: { fontSize: 14 } },
    tooltip: { formatter: '"{b}": {c} \n results' },
    grid: [
        {

            top: '0%',
            left: "0%",
            right: "0%",
            bottom: '0%'
        },
    ],
    animation: false,
    series: [
        {
            type: 'pie',
            name: '',
            animation: false,
            showEmptyCircle: true,
            radius: ['20%', '50%'],
            label: {
                position: 'outside',
                formatter: '{strong|{b}}\n{d}%',
                rich: {
                    strong: {
                        // fontWeight: 'bold',
                        color: '#b0b0b0',
                        fontSize: '12px'
                    }
                }
            },
            data: [],

        }
    ]
}
export default echartsOptionPieBreakdown