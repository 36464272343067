import PocketBase from 'pocketbase';
// const baseUrl = "http://127.0.0.1:8090"
const baseUrl = "https://api.wects.com/tcping"
const client = new PocketBase(baseUrl);


export async function loadRecords(location, isMagp, startDate, endDate) {
    // console.log('raw start end', startDate, endDate)
    const start = `${startDate}T0000`
    const end = `${endDate}T0000`
    // console.log('start end', start, end)
    const records = await client.records.getFullList(location, 200 /* batch size */, {
        filter: `id>="${start}" && id<="${end}"`,
    });

    // console.log('raw done')
    const processedRecords = await _buildRecords(records, isMagp)
    // console.log('processed done')
    return processedRecords
}

async function _buildRecords(rawList, isMagp) {
    const dataTag = isMagp ? 'magp' : 'mags'
    let resultList = []
    rawList.forEach(dateItem => {
        dateItem[dataTag].forEach(item => {
            let tmp = {}
            tmp.date = dateItem.id.slice(0, 10)
            tmp.time = item[0]
            tmp.respTime = item[1]
            resultList.push(tmp)
        })
    });
    // console.log(resultList)
    return resultList
}

export default { loadRecords }