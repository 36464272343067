export const echartsOptionBarBreakdown = {
    title: { text: 'response time', left: 'left', textStyle: { fontSize: 14 } },
    //tooltip: { formatter: '"{b}": {c} ms ({a})' },
    legend: { left: 'right', top: '0%' },
    grid: [
        {

            top: '20%',
            left: "0%",
            right: "0%",
            bottom: '15%'
        },
    ],
    animation: false,
    xAxis: {
        type: 'category',
        data: ['fast1', 'normal1', 'slow1'],
        axisLabel: { rotate: 0 }
    },
    yAxis: [{
        type: "value",
        inverse: false,
        show: false,
        max: 400


    },
    ],
    series: [
        {
            name: "mean",
            data: [],
            type: 'bar',
            barWidth: '25%',
            label: {
                show: true, position: 'top', rotate: 0, distance: 0,
                formatter: '{c}\nms',

            },

        },
        {
            name: "median",
            data: [],
            type: 'bar',
            barWidth: '25%',
            label: {
                show: true, position: 'top', rotate: 0, distance: 0, formatter: '{c}\nms',

            },

        }
    ]
};

export default echartsOptionBarBreakdown