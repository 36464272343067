export const echartsOptionScatter = {
    grid: [
        {
            top: '10%',
            left: "10%",
            right: "10%",
            bottom: "10%"
        },
    ],
    xAxis: {
        name: "response time(ms)",
        scale: false,
        max: 1000,
        interval: 50
    },
    yAxis: {
        scale: true,
        show: false

    },
    series: [
        {
            data: [],
            name: "",
            type: 'scatter',
            large: true,
            blendMode: 'Source-over',
            symbolSize: 2,
            animation: false
        }
    ],
}
export default echartsOptionScatter