<template>
  <el-row :gutter="15">
    <el-col
      :span="chartData.seriesName[1].length ? 12 : 24"
      v-show="chartData.seriesName[0].length"
    >
      <div class="breakdown">
        <div class="title-block">
          <div class="chart-title">{{ chartData.seriesName[0] }}</div>
          <div class="chart-subtitle">hourly breakdowns</div>
        </div>
        <el-row>
          <el-col
            v-for="(seriesData, k) in seriesPieBreakdown0"
            :key="k"
            :md="24"
            :lg="12"
            :xl="8"
          >
            <el-row type="flex" justify="center"
              ><ChartBreakdownPie
                :seriesData="seriesData"
                :eChartTitle="`${breakdowns[k][0]}:00 ~ ${
                  breakdowns[k][1] - 1
                }:59
                `" /><ChartBreakdownBar
                :seriesData="seriesBarBreakdown0[k]"
                :pieComments="pieComments"
                v-show="chartData.seriesName[1].length === 0"
            /></el-row>
          </el-col>
        </el-row>
      </div>
    </el-col>
    <el-col
      :span="chartData.seriesName[0].length ? 12 : 24"
      v-show="chartData.seriesName[1].length"
    >
      <div class="breakdown">
        <div style="text-align: center; padding: 30px 0px">
          <div class="chart-title">{{ chartData.seriesName[1] }}</div>
          <div class="chart-subtitle">hourly breakdowns</div>
        </div>

        <el-row>
          <el-col
            v-for="(seriesData, k) in seriesPieBreakdown1"
            :key="k"
            :md="24"
            :lg="12"
            :xl="8"
            style="float-left"
          >
            <el-row type="flex" justify="center"
              ><ChartBreakdownPie
                :seriesData="seriesData"
                :eChartTitle="`${breakdowns[k][0]}:00 ~ ${
                  breakdowns[k][1] - 1
                }:59
                `" /><ChartBreakdownBar
                :seriesData="seriesBarBreakdown1[k]"
                :pieComments="pieComments"
                v-show="chartData.seriesName[0].length === 0" /></el-row
          ></el-col>
        </el-row>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import statics from "../utils/statics";

import ChartBreakdownPie from "./ChartBreakdownPie.vue";
import ChartBreakdownBar from "./ChartBreakdownBar.vue";

export default {
  components: {
    ChartBreakdownPie,
    ChartBreakdownBar,
  },
  props: ["chartData", "pieComments", "thresholds", "breakdowns"],
  data() {
    return {
      seriesPieBreakdown0: [],
      seriesPieBreakdown1: [],
      seriesBarBreakdown0: [],
      seriesBarBreakdown1: [],
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.updateData(val);
      },
      immediate: false,
    },
    thresholds: {
      deep: true,
      handler() {
        this.updateData(this.chartData);
      },
      immediate: false,
    },
  },
  computed: {},
  methods: {
    updateData(val) {
      let tmp0 = (this.seriesPieBreakdown0 = statics.processSeriesBreakdown(
        val.rawData[0],
        this.breakdowns,
        this.thresholds,
        this.pieComments
      ));
      let tmp1 = (this.seriesPieBreakdown0 = statics.processSeriesBreakdown(
        val.rawData[1],
        this.breakdowns,
        this.thresholds,
        this.pieComments
      ));
      this.seriesPieBreakdown0 = tmp0[0];
      this.seriesBarBreakdown0 = tmp0[1];
      this.seriesPieBreakdown1 = tmp1[0];
      this.seriesBarBreakdown1 = tmp1[1];
    },
  },
  mounted() {
    window.vue = this;
  },
};
</script>

<style scoped>
.breakdown {
  background-color: white;
  box-shadow: 1px 1px 3px #e3e3e3;
}
.title-block {
  text-align: center;
  padding: 30px 0px;
}

.charts {
  border: 1px;
  border-color: #e0e0e0;
  border-style: solid;
  padding: 20px;
}
</style>