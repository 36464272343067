<template>
  <div class="chart-card">
    <div class="title">
      <i class="el-icon-data-analysis" /> <slot name="title"></slot>
    </div>
    <ChartBreakdown
      :chartData="chartData"
      :pieComments="echartsOptionCustom.pieThresholdsComments"
      :thresholds="echartsOptionCustom.pieThresholds"
      :breakdowns="echartsOptionCustom.pieBreakdowns"
    />
    <ChartBarPieHistogram
      :chartData="chartData"
      :hourRange="echartsOptionCustom.hourRange"
      :pieComments="echartsOptionCustom.pieThresholdsComments"
      :thresholds="echartsOptionCustom.pieThresholds"
    />
  </div>
</template>

<script>
import ChartBarPieHistogram from "./ChartBarPieHistogram.vue";
import ChartBreakdown from "./ChartBreakdown.vue";

export default {
  components: {
    ChartBarPieHistogram,
    ChartBreakdown,
  },
  props: ["echartsOptionCustom", "chartData"],
  data() {
    return {};
  },
  watch: {},
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.chart-card {
  margin-top: 30px;
  padding: 20px;
  border-style: solid;
  border-color: #e0e0e0;
  border-width: 1px;
}
.title {
  letter-spacing: 0.2em;
  color: #a0a0a0;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 20px;
}
</style>