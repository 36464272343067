export const echartsOptionHistogram = {
    tooltip: {},
    grid: [
        {

            top: '10%',
            left: "10%",
            right: "10%",
            bottom: '10%'
        },
    ],
    animation: true,
    xAxis: {
        type: 'category',
        //var thresholds = [100, 200, 300, 1000]
        data: ['0-99ms', '100-199ms', '200-299ms', '300-399ms', '400-999ms', '1000ms(timeout)'],
        //data: ['<50ms', '50-100ms', '100-150ms', '150-200ms', '200-250ms', '250-300ms', '300-400ms', '400-500ms', '500-600ms', '600-700ms', '700-800ms', '800-900ms', '900-1000ms', '>1000ms'],
        //data: [20, 40, 60, 80, 100, 120, 140, 160, 180, 200, 220, 240, 260, 280, 300, 320, 340, 360, 380, 400, 420, 440, 460, 480, 500, 520, 540, 560, 580, 600, 620, 640, 660, 680, 700, 720, 740, 760, 780, 800, 820, 840, 860, 880, 900, 920, 940, 960, 980, 1000],
        axisLabel: { rotate: 0 }
    },
    yAxis: [{
        type: "value",
        inverse: false,
        show: false,


    },
    {
        //alignTicks: true,
        type: "value",
        inverse: false,
        show: false,
        max: 1200,
        offset: 0
    },
    {
        //alignTicks: true,
        type: "value",
        inverse: false,
        show: false,
        max: 1200,
    }
    ],
    /*dataZoom: [
        {
            id: 'dataZoomX',
            type: "slider",
            xAxisIndex: 0,
            filterMode: "empty",
            start: 0,
            end: 1000,

        }
    ],*/
    series: [
        {
            name: "results",
            data: [],
            yAxisIndex: 0,
            type: 'bar',
            label: {
                show: true, position: 'top',
                /*
                align: 'middle',
                verticalAlign: 'middle',
                position: 'inside',
                distance: 15,*/
                formatter: '{strong|{c}}\n{a}',
                rich: {
                    strong: {
                        fontWeight: "bold",
                        fontSize: '16px',
                    }
                }
            },
            barWidth: '30%'
        },
        {
            name: "mean",
            data: [],
            yAxisIndex: 1,
            type: 'bar',
            label: {
                show: true, position: 'top',
                formatter: '{name|{a}}\n{strong|{c}}\nms',
                rich: {
                    strong: {
                        // fontWeight: "bold",
                        fontSize: '11px',
                    },
                    name: {
                        fontSize: "11px"
                    }
                }
            },
            barWidth: '20%',
        },
        {
            name: "median",
            data: [],
            yAxisIndex: 2,
            type: 'bar',
            label: {
                show: true, position: 'top', formatter: '{name|{a}}\n{strong|{c}}\nms',
                rich: {
                    strong: {
                        // fontWeight: "bold",
                        fontSize: '11px',
                    },
                    name: {
                        fontSize: "11px"
                    }
                }
            },
            barWidth: '20%',

        }
    ]
};

export default echartsOptionHistogram