<template>
  <div>
    <div ref="bar" style="width: 280px; height: 280px"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import echartsOptionBarBreakdown from "../utils/eCharts/echartsOptionBarBreakdown";
export default {
  props: ["seriesData", "pieComments"],
  data() {
    return { option: {}, chart: null };
  },
  watch: {
    seriesData: {
      deep: true,
      handler(val) {
        this.updateData(val);
      },
      immediate: false,
    },
    option: {
      deep: true,
      handler(val) {
        this.drawChart(val);
      },
      immediate: false,
    },
  },
  computed: {
    xAxisData() {
      let tmp = JSON.parse(JSON.stringify(this.pieComments));
      tmp.pop();
      return tmp;
    },
  },
  methods: {
    updateData(val) {
      this.option.series[0].data = val.mean;
      this.option.series[1].data = val.median;
    },
    initChart() {
      this.chart = echarts.init(this.$refs.bar);
      this.option.xAxis.data = this.xAxisData;
      this.updateData(this.seriesData);
    },
    drawChart(option) {
      this.chart.setOption(option);
    },
  },
  mounted() {
    this.option = JSON.parse(JSON.stringify(echartsOptionBarBreakdown));
    this.initChart();
  },
};
</script>

<style>
</style>