export const echartsOptionBar = {
    tooltip: {
        trigger: "axis",
        axisPointer: { show: true, type: "cross", animation: false }
    },
    legend: {},
    grid: [
        {
            top: '10%',
            left: "10%",
            right: "10%",
            bottom: '15%'
        },
    ],
    xAxis: {
        type: "category",
    },
    yAxis: {
        name: "response time",
        type: "value",
        axisLabel: {
            formatter: "{value}ms",
        },
    },
    dataZoom: [
        {
            id: 'dataZoomX',
            type: "slider",
            xAxisIndex: 0,
            filterMode: "empty",
            start: 0,
            end: 100,

        },
        {
            id: 'dataZoomY',
            type: "slider",
            yAxisIndex: 0,
            filterMode: "none",
            start: 0,
            right: '5%',
            showDataShadow: false,
            endValue: 1000
        },
    ],
    series: [
        {
            data: [],
            name: "",
            type: "bar",
            large: true,
            itemStyle: {
                color: "rgba(30, 144, 255, 0.5)"
            },
            emphasis: {
                disabled: true,
            },

            /*
            markLine: {
                slient: true,
                animation: false,
                precision: 0,
                lineStyle: {
                    width: 2,
                    color: "rgb(255, 0, 0)",
                    type: "solid"
                },
                data: [
                    {
                        type: "average",
                    }
                ]
            }
            */
        },
        {
            data: [],
            name: "",
            type: "bar",
            large: true,
            itemStyle: {
                color: "rgba(160, 160, 160, 0.2)"
            },
            emphasis: {
                disabled: true,
            },
            /*
            markLine: {
                slient: true,
                animation: false,
                precision: 0,
                lineStyle: {
                    width: 2,
                    color: "rgb(0, 0, 255)",
                    type: "dashed"
                },
                data: [
                    {
                        type: "average",
                    }
                ]
            }
            */
        },
    ],
}
export default echartsOptionBar